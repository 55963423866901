import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

function ImageProcessing({ image, alt, classes = "" }) {
  if (image.split(".").pop() === "svg")
    return <img className="mx-auto" alt={alt} src={`/${image}`} />;

  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  const foundImage = data.images.edges.find((n) => {
    return n.node.relativePath.includes(image);
  });

  if (!foundImage) {
    return <div>Image not found</div>;
  }

  return (
    <GatsbyImage
      image={foundImage.node.childImageSharp.gatsbyImageData}
      className={`mx-auto ${classes}`}
      alt={alt}
      loading="lazy"
    />
  );
}

ImageProcessing.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classes: PropTypes.string
};

export default ImageProcessing;