import React from "react";

function trustedbrands() {
  return (
    <section className="mb-20  max-w-5xl mx-auto">
      <div className=" px-8 mobile:px-2">
        <h3 className="text-center text-gray-900 w-2/3 mx-auto mb-16 text-3xl capitalize font-bold mobile:text-2xl mobile:w-full tablet:w-full">
          Our SSL certificates are from some of the most trusted brands in
          Online Security.
        </h3>
        <div className="grid grid-cols-3 justify-items-center mobile:grid-cols-1">
          <img
            className="w-1/2 mobile:p-10 mobile:w-4/5"
            src={"/ssl/geotrust-logo.png"}
            alt={"geotrust-logo"}
          />
          <img
            className="w-1/2 mobile:p-10 mobile:w-4/5"
            src={"/ssl/rapidssl-logo.png"}
            alt={"rapidssl-logo"}
          />
          <img
            className="w-1/2 mobile:p-10 mobile:w-4/5"
            src={"/ssl/symantec-logo.png"}
            alt={"symantec-logo"}
          />
        </div>
      </div>
    </section>
  );
}
export default trustedbrands;
