import React from "react";

function icon_list(props) {
	return (
		<section className="px-32 py-14 mobile:px-2 tablet:px-4 laptop:px-16">
			<div
				className={`w-full grid grid-cols-${props.columns} mx-auto mobile:grid-cols-1 tablet:grid-cols-2`}
			>
				{props.icons.map((items, key) => (
					<div className="text-center mx-auto py-5" key={key}>
						<img
							alt="blue security icon for ssl features "
							className="mx-auto py-5 text-center w-18"
							src={items.image}
						/>
						<p className="font-semibold text-lg">{items.description}</p>
					</div>
				))}
			</div>
		</section>
	);
}

export default icon_list;
