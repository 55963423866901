import PropTypes from "prop-types";
import { Link } from "gatsby";
import React, { useCallback } from "react";

export default function PlanCard(props) {
  const handleGetNowClick = useCallback((e) => {
    e.preventDefault();
    console.log("handleGetNowClick triggered");
  
    const url = props.productID.toString().startsWith("/")
      ? props.productID
      : `https://client.hostylus.com/cart.php?a=add&pid=${props.productID}`;
    
    console.log("URL:", url);
  
    if (typeof window !== 'undefined') {
      console.log("Window is defined");
      if (typeof window.gtag === 'function') {
        console.log("gtag is a function");
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16624821405/UA_4CJKar8AZEJ3Jqvc9',
          'value': parseFloat(props.price),
          'currency': 'USD',
          'event_callback': function() {
            console.log("gtag event callback fired");
            window.location.href = url;
          }
        });
        console.log("gtag event fired");
      } else {
        console.log("gtag is not a function");
        window.location.href = url;
      }
    } else {
      console.log("Window is not defined");
      // This shouldn't happen in a browser environment
    }
  }, [props.productID, props.price]);

  return (
    <div className="planBox border-solid border-2 bg-white rounded py-6 hover:border-blue-300 mobile:w-4/5 ">
      {props.planSVG}
      <div className="px-6">
        <h2 className="font-bold text-lg text-center capitalize mb-3">
          {props.header}
        </h2>
        <p className="text-sm text-gray-600 text-center">{props.desc}</p>
        <div className="text-center capitalize">
          <sup className="text-black text-xl">$</sup>
          <span className="font-bold text-3xl text-blue-700">
            {props.price}
          </span>
          <span className="text-gray-500 text-sm">/{props.perWhat}</span>
        </div>
        <div className="h-80 py-5 border-solid border-b-2 grid">{props.myItems}</div>
        <div className="flex flex-wrap content-center justify-center">
          {props.productID.toString().startsWith("/") ? (
            <Link
              className="btn mt-6 font-bold text-sm text-black border-2 border-black px-8 py-2 uppercase inline-block rounded-full"
              to={props.productID}
              onClick={handleGetNowClick}
            >
              get now
            </Link>
          ) : (
            <a
              className="btn mt-6 font-bold text-sm text-black border-2 border-black px-8 py-2 uppercase inline-block rounded-full"
              href={`https://client.hostylus.com/cart.php?a=add&pid=${props.productID}`}
              // onClick={handleGetNowClick}
            >
              get now
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

PlanCard.propTypes = {
  planSVG: PropTypes.any,
  savings: PropTypes.string,
  hideSave: PropTypes.string,
  desc: PropTypes.string,
  header: PropTypes.string.isRequired,
  price: PropTypes.any,
  myItems: PropTypes.any.isRequired,
  productID: PropTypes.any.isRequired,
  perWhat: PropTypes.string.isRequired,
};