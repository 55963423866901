import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function imageRight(props) {
  return (
    <section>
      <div className="tablet:p-8  flex m-auto laptop:my-10 bg-white mobile:my-10 mobile:flex-col-reverse tablet:mt-16 laptop:p-6">
        <div className="w-1/2 pl-40 tablet:pl-0  mobile:pl-0 m-auto mobile:text-center mobile:px-8 tablet:px-4 mobile:m-4 mobile:w-full tablet:w-full  laptop:pl-10 ">
          <h2 className="  font-extrabold mobile:mt-5 mobile:text-center tablet:text-xl text-3xl mb-3  tablet:w-4/5 laptop:w-4/5">
            {props.header}
          </h2>
          <ul className="text-gray-700 font-semibold list-disc list-inside my-6 mobile:mx-4  text-left text-sm">
            {props.listItems}
          </ul>
          <p className="uppercase font-bold text-gray-500">
            {props.smallHeader}
          </p>
          <div className="font-light  my-3 mobile:w-full mobile:px-10   mobile:text-center">
            {props.desc}
          </div>
          <div
            className={` mobile:w-full mobile:text-center  ${props.hideLearnMore}`}
          >
            {props.btnLink===null?"":
            props.btnLink.charAt(0) === "/" ? 
            (<><Link
              to={props.btnLink}
              className="inline-block text-blue-600 font-black uppercase text-md underline mr-1 min-w-min"
            >
              {props.btnText}
            </Link>
            <img
              className={`inline-block ${props.hideArrow}`}
              src={"/arrow.svg"}
            /></>)
          :
          (<>
            <a
              href={props.btnLink}
              className="inline-block text-blue-600 font-black uppercase text-md underline mr-1 min-w-min"
              rel="noreferrer noopener"
              target="_blank"
            >
              {props.btnText}
            </a>
            <img
              className={`inline-block ${props.hideArrow}`}
              src={"/arrow.svg"}
            />
            </>)
            }
          </div>
          <div
            className={` w-2/3 mobile:w-full mobile:text-center my-6 ${props.hideShopNow}`}
          >
            <Link
              to={"/ssl"}
              className="text-black-600 font-semibold uppercase text-md rounded-full border-2 border-black py-2 px-6 mr-2"
            >
              Shop now
            </Link>
          </div>
        </div>
        <div className="w-1/2 mx-10 my-10 tablet:m-auto mobile:m-auto mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
          {/* <img className="m-auto mobile:p-4" src={props.image}/> */}
          {props.image}
        </div>
      </div>
    </section>
  );
}

export default imageRight;
imageRight.propTypes = {
  image: PropTypes.object.isRequired, // must be a string and defined
  header: PropTypes.string.isRequired, // must be a string and defined
  smallHeader: PropTypes.string,
  desc: PropTypes.any.isRequired, // must be a string and defined
  btnLink: PropTypes.string, // must be a string and defined
  btnText: PropTypes.string, // must be a string and defined
  hideLearnMore: PropTypes.string, // must be a string and defined
  hideShopNow: PropTypes.string, // must be a string and defined
  hideArrow: PropTypes.string, // must be a string and defined: PropTypes.string, // must be a string and defined
};
