import React from "react";
import PropTypes from "prop-types";
function featureBlock(props) {
  return (
    <div className="p-4">
      {/*<img*/}
      {/*    className="mx-auto"*/}
      {/*    src={props.image}*/}
      {/*    alt="A corgi smiling happily"*/}
      {/*/>*/}
      <h2 className="text-center text-xl text-black  font-bold">
        {props.header}
      </h2>
      <p className="text-center text-md text-black py-3">{props.desc}</p>
    </div>
  );
}
export default featureBlock;
featureBlock.PropTypes = {
  // image: PropTypes.any.isRequired, // must be a string and defined
  header: PropTypes.string.isRequired, // must be a string and defined
  desc: PropTypes.string, // must be a string and defined
  // alt: PropTypes.string.isRequired, // must be a string and defined
};
