import React from "react";
import { Link } from "gatsby";
function Header() {
  return (
    <header id="header" style={{ zIndex: "10" }}>
      <div className="mobile:hidden tablet:hidden px-6  bg-white shadow flex flex-wrap  items-center lg:py-0 mobile:py-0 tablet:py-0 py-3">
        <div className="w-full visible mobile:hidden tablet:hidden h-full align-middle inline-block ">
          <ul className="text-center">
            <li className="inline-block text-sm  hover:font-bold delay-50  border-b-2 border-transparent mx-2 px-3  py-3 hover:border-blue-600 hover:text-blue-600">
              {" "}
              <Link
                className="font-semibold hover:font-bold py-7"
                to="/ssl#header"
              >
                Overview
              </Link>
            </li>
            <li className="inline text-sm  hover:font-bold delay-50  border-b-2 border-transparent mx-3 px-2  py-3  hover:border-blue-600 hover:text-blue-600">
              <Link
                to={"/SSL/DV#header"}
                className="font-semibold hover:font-bold py-7"
              >
                Standard SSL (DV)
              </Link>
            </li>
            <li className="inline text-sm  hover:font-bold delay-50   border-b-2 border-transparent mx-3 px-2 py-3 hover:border-blue-600 hover:text-blue-600">
              <Link
                to={"/SSL/OV#header"}
                className=" font-semibold hover:font-bold py-7"
              >
                Organisation Validation (OV)
              </Link>
            </li>
            <li className="inline text-sm  hover:font-bold delay-50   border-b-2 border-transparent mx-3 px-2 py-3 hover:border-blue-600 hover:text-blue-600">
              <Link
                to={"/SSL/EV#header"}
                className=" font-semibold hover:font-bold py-7"
              >
                Extended Validation (EV)
              </Link>
            </li>
            <li className="inline text-sm  hover:font-bold delay-50   border-b-2 border-transparent mx-3 px-2 py-3 hover:border-blue-600 hover:text-blue-600">
              <Link
                to={"/SSL/Wildcard#header"}
                className=" font-semibold hover:font-bold py-7"
              >
                Wildcard
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/*end desktop menu */}
      <div className="my-3">
        <div className="grid gap-4 grid-cols-3 laptop:hidden desktop:hidden wide-desktop:hidden">
          <div>{/*placeholder*/}</div>
          <label
            htmlFor="ssl-navbar-toggle"
            className="flex-1 flex items-center pointer-cursor laptop:hidden desktop:hidden wide-desktop:hidden block "
          >
            <svg
              className="fill-current text-gray-900 m-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 20 20"
            >
              <title>ssl-navbar</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </label>
        </div>
        <input className="hidden" type="checkbox" id="ssl-navbar-toggle" />
        <div
          className="hidden lg:flex lg:items-center mobile:w-full tablet:w-full"
          id="ssl-navbar"
        >
          <nav className="text-center p-8 mobile:w-full tablet:w-full">
            <ul className="inline-block">
              <li className="lg:p-4 py-3 px-0 block">
                {" "}
                <Link
                  className="font-semibold hover:font-bold py-7"
                  to="/ssl#header"
                >
                  OverView
                </Link>
              </li>
              <li className="lg:p-4 py-3 px-0 block">
                <Link
                  to={"/SSL/DV#header"}
                  className="font-semibold hover:font-bold py-7"
                >
                  Standard SSL (DV)
                </Link>
              </li>
              <li className="lg:p-4 py-3 px-0 block">
                <Link
                  to={"/SSL/OV#header"}
                  className=" font-semibold hover:font-bold py-7"
                >
                  Organisation Validation (OV)
                </Link>
              </li>
              <li className="lg:p-4 py-3 px-0 block">
                <Link
                  to={"/SSL/EV#header"}
                  className=" font-semibold hover:font-bold py-7"
                >
                  Extended Validation (EV)
                </Link>
              </li>
              <li className="inline text-sm  hover:font-bold delay-50   border-b-2 border-transparent mx-3 px-2 py-3 hover:border-blue-600 hover:text-blue-600">
                <Link
                  to={"/SSL/Wildcard#header"}
                  className=" font-semibold hover:font-bold py-7"
                >
                  Wildcard
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
