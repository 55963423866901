import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Landing from "../../components/landingSection";
import icons from "../../DB/icon_list_DV";
import ImageRight from "../../components/imageRight";
import Image from "../../components/imageProcessing";
import Icon_list from "../../components/SSL_COMP/icon_list";
import Cases from "../../DB/dv-cases";
import Case from "../../components/SSL_COMP/featureBlock";
// import Packages from "../../DB/plan-card-dv";
import PlanCard from "../../components/plan_card";
import LI from "../../components/plan-li";
import Feature from "../../components/SSL_COMP/FeaturesWithoutIcon";
import TrustedBrands from "../../components/SSL_COMP/trustedbrands";
import NavBar from "../../components/SSL_COMP/SSL-nav-bar";
/* eslint react/prop-types: 0 */
import { graphql, useStaticQuery } from "gatsby";

function DV() {
	const data = useStaticQuery(graphql`
		query DVQuery {
			allProducts(filter: { productID: { in: [4, 6] } }) {
				nodes {
					productID
					productGroupID
					name
					description
					annually
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[
					`ssl`,
					`use ssl to connect`,
					`ssl certificate`,
					`ssl connection`,
					"ssl meaning",
					"ssl protocols",
					"ssl encryption",
					"ssl technology",
					"ssl protocol",
					"define ssl",
					"what is ssl",
					"website encryption",
					"secure socket layer",
					"ssl server",
					"website ssl",
					"ssl port",
					"ssl website",
					"ssl support",
					"ssl definition",
					"ssl vs tls",
					"ssl vpn",
					"ssl tls",
					"is ssl secure",
					"ssl checker",
				]}
				title="Domain Validated SSL - SSL Certificates"
				lang="en"
				image="hostylus-card-preview.jpg"
				description="Your best choice for a personal site that involves no confidential info, it assures your website is HTTPS enabled so everyone's privacy is protected 24/7."
			/>

			<Landing
				image={
					<Image
						image={"sslLanding.png"}
						alt={"blue lock with user data in the background"}
					/>
				}
				header={"Domain Validated SSL"}
				sub_header={"Secure your website in just a few minutes!"}
				btnText={"pricing"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<NavBar />
			<section className="w-full pt-10">
				<ImageRight
					header={"What is Standard DV SSL?"}
					desc={
						<>
							<p>
								Domain Validation certificates offer an economical and quick way
								to implement SSL to your website. Domain Validated certificates
								verify you own the domain, but do not perform any additional
								organization level validation.
							</p>
							<p>
								Domain Validated certificates are ideal for personal websites,
								blogs and social media, or any sites that are not transmitting
								private and confidential information. A Domain Validated
								certificate activates the browser padlock and enables the use of
								https to assure your website visitors and customers that you
								take their privacy seriously.
							</p>
						</>
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image image={"ssl/secure.svg"} alt={"-secured green bar lock"} />
						</div>
					}
					btnLink="#"
				/>
			</section>
			<section className="features w-3/5 px-40 mobile:px-12 mobile:w-full">
				<h2 className="text-3xl text-black font-bold  capitalize mobile:text-center">
					Recommended Use Cases
				</h2>
				<div className="features  grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-4 laptop:px-6">
					{Cases.map((feature, key) => (
						<Case
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
						/>
					))}
				</div>
			</section>
			<section className="w-1/2"></section>
			<section className="w-full p-20 my-20 mobile:p-10">
				<div className=" mx-auto grid grid-cols-3 mobile:grid-cols-1">
					<Feature
						header={"Delivered in Minutes for Instant Protection"}
						desc={
							"The fastest and most affordable way to activate SSL protection for your website, issuance is quick and often fully automated."
						}
					/>
					<Feature
						header={"Trust Site Seal"}
						desc={
							"Our SSL Certificates come with a trust seal that has been proven to increase visitor confidence and customer conversions."
						}
					/>
					<Feature
						header={"Boost your Google Ranking"}
						desc={
							"Google uses SSL/HTTPS as a factor in determining search engine ranking. Add SSL today to help boost your Google ranking!"
						}
					/>
				</div>
			</section>
			<section className="w-full p-10  bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900 my-20 mobile:p-10">
				<section
					id="pricing"
					className="max-w-5xl mx-auto my-16 mobile:pb-3 tablet:mx-5 "
				>
					<h2 className="text-4xl text-white font-bold text-center">
						Certificate Pricing
					</h2>
					<div className="grid grid-cols-2 gap-10 flex justify-items-stretch mt-6 max-w-2xl mx-auto mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center py-8">
						{data.allProducts.nodes.map(function (items, key) {
							var stripedHtml = items.description.replace(/<[^>]+>/g, "");
							var description = stripedHtml.split("\n");
							return (
								<PlanCard
									key={key}
									price={items.annually}
									header={items.name}
									productID={items.productID}
									perWhat={"yr"}
									myItems={
										<ul
											style={{
												listStyleImage:
													"url('https://ik.imagekit.io/softylus/tick_PLS8tkQNa.jpg')",
												listStylePosition: "inside",
											}}
											className="list-disc"
										>
											{description.slice(1).map((listItem, listIndex) => (
												<LI
													key={listIndex}
													LI={listItem}
													textSize={"text-sm"}
												/>
											))}
										</ul>
									}
								/>
							);
						})}
					</div>
				</section>
			</section>
			<section className="mx-auto text-center py-10">
				<div>
					<p className="text-3xl font-bold w-full px-10">
						There&apos;s also a lot more reasons why you should consider adding
						SSL to your website:
					</p>
				</div>
				<Icon_list icons={icons} columns={"4"} />
			</section>
			<TrustedBrands />
		</Layout>
	);
}

export default DV;
