import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

function featureBlock(props) {
	if (props.image.split(".").pop() === "svg")
		return (
			<div className="border-2 mx-3 p-4">
				<img className="mx-auto w-11" alt={props.alt} src={`/` + props.image} />
				<h2 className="text-center text-base mt-2 ">{props.header}</h2>
			</div>
		);
	return (
		<StaticQuery
			query={graphql`
				{
					images: allFile {
						edges {
							node {
								relativePath
								name
								childImageSharp {
									gatsbyImageData(width: 600, layout: CONSTRAINED)
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				const image = data.images.edges.find((n) => {
					return n.node.relativePath.includes(props.image);
				});
				if (!image) {
					return null;
				}
				// if(image.extension){
				//     return(image.extension)
				// }
				return (
					<div className="border-2 rounded-lg p-8">
						<GatsbyImage
							image={image.node.childImageSharp.gatsbyImageData}
							className="mx-auto"
							alt="blue security icon for ssl features"
						/>
						<h2 className="text-center text-xl font-bold">{props.header}</h2>
					</div>
				);
			}}
		/>
	);
}
export default featureBlock;
featureBlock.PropTypes = {
	image: PropTypes.any.isRequired, // must be a string and defined
	header: PropTypes.string.isRequired, // must be a string and defined
	desc: PropTypes.string, // must be a string and defined
	alt: PropTypes.string.isRequired, // must be a string and defined
};
