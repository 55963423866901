import React from "react";
import PropTypes from "prop-types";
function PlanLI(props) {
  return (
    <li className={`${props.textSize} {text-gray-600} ${props.Dell}  my-3`}>
      {props.LI}
    </li>
  );
}
export default PlanLI;
PlanLI.propTypes = {
  LI: PropTypes.string.isRequired, // must be a node and defined
  textSize: PropTypes.string.isRequired, // must be a node and defined
  Dell: PropTypes.string, // must be a node and defined
};
