export default [
  {
    image: "ssl/blog.svg",
    title: "Blogs",
    imageAlt: "Blogs",
  },
  {
    image: "ssl/information.svg",
    title: "Informational Pages",
    imageAlt: "Informational Pages",
  },
  {
    image: "ssl/server2server.svg",
    title: "Server-to-server Communications",
    imageAlt: "Server-to-server Communications",
  },
];
