import React from "react";
// import Img from "gatsby-image";
import propTypes from "prop-types";

function wordpressHosting(props) {
  return (
    <div className="flex  bg-gray-50  mobile:flex-col-reverse">
      <div className="w-1/2  my-auto mobile:my-4 mobile:text-center tablet:align-bottom mobile:w-full tablet:ml-8 tablet:w-full">
        <span className="text-sm block w-2/3 m-auto mobile:text-xs uppercase font-bold tracking-widest text-black opacity-50 mobile:text-center tablet:w-full laptop:w-11/12">
          {props.smallHeader}
        </span>
        <h1 className="capitalize text-black font-bold w-2/3 m-auto mobile:mt-5 mobile:w-4/5  text-4xl mobile:text-2xl tablet:text-3xl tablet:mt-5  tablet:w-full laptop:w-11/12 ">
          {props.header}
        </h1>
        <p className="font-light w-2/3 my-3 m-auto tablet:text-sm mobile:w-full mobile:px-10 tablet:w-full laptop:w-11/12  mobile:text-center">
          {props.sub_header}
        </p>

        <p style={{color:"#575757"}} className="font-normal text-gray-400  w-2/3 my-5 m-auto tablet:text-sm mobile:w-full mobile:px-10   mobile:text-center tablet:w-full laptop:w-11/12">
          {props.desc} <span className="font-black">{props.boldText}</span>
        </p>
        <div className="w-2/3 tablet:w-full mx-auto laptop:w-11/12">
          <a href={props.btnURL}>
            <button className=" outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-3  rounded-3xl px-8 border-2 mb-2    uppercase laptop:mt-2">
              {props.btnText}
            </button>
          </a>
          <a href={props.secondary_btnURL} className={props.hideSecondary_btn}>
            <button className="outline-none py-2 tablet:text-xs font-black text-black mobile:mr-3 uppercase rounded-3xl px-8 border-2 border-black tablet:mt-4 mobile:mt-4">
              {props.secondary_btn}
            </button>
          </a>
        </div>
      </div>

      <div className="w-1/2 mx-10 mobile:m-auto mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
        {/* <img className="m-auto mobile:p-4" src={props.image}/> */}
        {props.image}
      </div>
    </div>
  );
}
export default wordpressHosting;
wordpressHosting.propTypes = {
  image: propTypes.object.isRequired, // must be a string and defined
  smallHeader: propTypes.string, // must be a string and defined
  header: propTypes.string.isRequired, // must be a string and defined
  desc: propTypes.string, // must be a string and defined
  btnURL: propTypes.string.isRequired,
  btnText: propTypes.string.isRequired,
  boldText: propTypes.string,
  secondary_btn: propTypes.string, // must be a string and defined
  secondary_btnURL: propTypes.string,
  hideSecondary_btn: propTypes.string,
};
